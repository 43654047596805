import React, {useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

function Contact() {
    const [captcha, setCaptcha] = useState(false)
    const [msg,setMsg] = useState("")
    const [form, setForm] = useState({
        surename:"",
        name:"",
        phone:"",
        email:"",
        subject:"",
        message:""
    })

    const sendMail = async (e) => {
        // eslint-disable-next-line no-unused-expressions
        e.preventDefault();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const {name,email,subject,message} = form;
        if(name && email && subject && message){
            if(captcha){
               try{
                 const res = await axios.post('https://clerksoftware.com/contact.php', form, { headers: {'Content-Type': 'application/json'}})
                 if(res.data.msg){
                     setMsg(res.data.msg)
                 }
               } catch (err){
                   setMsg('Somehing wrong')
                   console.log(err)
               }
            }
        }else{
            setMsg('Please fill the required fields!')
        }
    }

    return (
       <>

           <section className="page-header single-header bg_img oh" data-background="./assets/images/page-header.png">
               <div className="bottom-shape d-none d-md-block">
                   <img src="./assets/css/img/page-header.png" alt="css"/>
               </div>
           </section>

           <section className="contact-section padding-top padding-bottom">
               <div className="container">
                   <div className="section-header mw-100 cl-white">
                       <h5 className="cate">{msg}</h5>
                       <h2 className="title">Get in Touch with Clerk Software</h2>
                       <p>Contact Us to Take Your Project to the Next Level.</p>
                   </div>
                   <div className="row justify-content-center justify-content-lg-between">
                       <div className="col-lg-7">
                           <div className="contact-wrapper">
                               <h4 className="title text-center mb-30">Get in Touch</h4>
                               <form className="contact-form" id="contact_form_submit">
                                   <div className="form-group">
                                       <label form="surename">Your Company Name (Optional)</label>
                                       <input type="text" placeholder="Enter Your Company Name"  name="surename" value={form.surename}
                                       onChange={e=>setForm(prevState => ({...prevState, surename:e.target.value}))}/>
                                   </div>
                                   <div className="form-group">
                                       <label form="name">Your Full Name</label>
                                       <input type="text" placeholder="Enter Your Full Name" name="name" required value={form.name}
                                              onChange={e=>setForm(prevState => ({...prevState, name:e.target.value}))}/>
                                   </div>
                                   <div className="form-group">
                                       <label form="phone">Phone Number</label>
                                       <input type="text" placeholder="Enter Your Phone Number " name="phone"
                                              value={form.phone}
                                              onChange={e=>setForm(prevState => ({...prevState, phone:e.target.value}))}/>
                                   </div>
                                   <div className="form-group">
                                       <label form="email">Your Email </label>
                                       <input type="email" placeholder="Enter Your Email " name="email" required
                                              value={form.email}
                                              onChange={e=>setForm(prevState => ({...prevState, email:e.target.value}))}/>
                                   </div>
                                   <div className="form-group">
                                       <label form="subject">Your Subject</label>
                                       <input type="text" placeholder="Enter Your Subject " name="subject" required
                                              value={form.subject}
                                              onChange={e=>setForm(prevState => ({...prevState, subject:e.target.value}))}
                                       />
                                   </div>
                                   <div className="form-group mb-0">
                                       <label form="message">Your Message </label>
                                       <textarea id="message" placeholder="Enter Your Message" required name="message"
                                                 value={form.message}
                                                 onChange={e=>setForm(prevState => ({...prevState, message:e.target.value}))}
                                       />

                                   </div>
                                   <div className="form-group mb-0">
                                       <ReCAPTCHA
                                           sitekey="6LeNgD8bAAAAADBMj1o2yBIjF6XrgiZb-uGSz6dg"
                                           onChange={e => setCaptcha(e)}
                                       />
                                   </div>
                                   <div className="form-group">
                                       <input type="submit" value="Send Message" onClick={sendMail}/>
                                   </div>
                               </form>
                           </div>
                       </div>
                       <div className="col-lg-4">
                           <div className="contact-content">
                               <div className="man d-lg-block d-none">
                                   <img src="./assets/images/contact/man.png" alt="bg"/>
                               </div>

                               <div className="contact-area">
                                   <div className="contact-item">
                                       <div className="contact-thumb">
                                           <img src="./assets/images/contact/contact1.png" alt="contact"/>
                                       </div>
                                       <div className="contact-contact">
                                           <h5 className="subtitle">Email Us</h5>
                                           <a href="Mailto:info@mosto.com">info@clerksoftware.com</a>
                                       </div>
                                   </div>
                                   <div className="contact-item">
                                       <div className="contact-thumb">
                                           <img src="./assets/images/contact/contact2.png" alt="contact"/>
                                       </div>
                                       <div className="contact-contact">
                                           <h5 className="subtitle">Call Us</h5>
                                           <a href="Tel:+447306128764">+44 730 612-87-64</a>

                                       </div>
                                   </div>
                                   <div className="contact-item">
                                       <div className="contact-thumb">
                                           <img src="./assets/images/contact/contact3.png" alt="contact"/>
                                       </div>
                                       <div className="contact-contact">
                                           <h5 className="subtitle">Address</h5>
                                           <p>183, The Kell, Gillingham Gate Road, Gillingham, <br/>ME4 4SB<br/><b>Business Hours</b><br/>
                                               Mon. - Fri. 8:30 AM to 6:30 PM</p>
                                           <p><b>Company Number:</b> <a target="_blank" href="https://find-and-update.company-information.service.gov.uk/company/13424297">13424297</a></p>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </section>
       </>
    );
}

export default Contact;