import React from 'react';
import {Link} from "react-router-dom";

function Header() {
    return (
        <header className="header-section">
            <div className="container">
                <div className="header-wrapper">
                    <div className="logo">
                        <Link reloadDocument to="/">
                            <img src="./assets/images/logo/logo.png" alt="logo"/>
                        </Link>
                    </div>
                    <ul className="menu">
                        <li>
                            <Link reloadDocument to="/">Home</Link>

                        </li>
                        <li>
                            <Link reloadDocument to="/about">About us</Link>
                        </li>


                        <li>
                            <Link reloadDocument to="/contact">contact</Link>
                        </li>

                    </ul>
                    <div className="header-bar d-lg-none">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="header-right">
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                    </div>


                </div>
            </div>
        </header>
    );
}

export default Header;