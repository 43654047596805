import React from 'react';
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="footer-section bg_img"
                data-background="./assets/images/footer/footer-bg.jpg">
            <div className="container">
                <div className="footer-top padding-top padding-bottom">
                    <div className="logo">
                        <Link to="/" reloadDocument>
                            <img src="./assets/images/logo/logoters.png" alt="logo"/>
                        </Link>
                    </div>
                    <ul className="social-icons">
                        <li>
                            <a href="https://www.facebook.com/clerksoftware" target="_blank"><i
                                className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/ClerkSoftware" className="active" target="_blank"><i
                                className="fab fa-twitter"></i></a>
                        </li>

                        <li>
                            <a href="https://www.instagram.com/clerksoftware/" target="_blank"><i
                                className="fab fa-instagram"></i></a>
                        </li>
                    </ul>
                </div>
                <div className="footer-bottom">
                    <ul className="footer-link">
                        <li>
                            <Link to="/about" reloadDocument>About US</Link>
                        </li>
                        <li>
                            <Link to="/contact" reloadDocument>Contact</Link>
                        </li>

                    </ul>
                </div>
                <div className="copyright">
                    <p>
                        Copyright © 2021.All Rights Reserved By <Link to="/" reloadDocument>Clerk Software</Link>
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;