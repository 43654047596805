import React from 'react';

function PreLoader() {
    return (
        <>
            <div className="preloader">
                <div className="preloader-inner">
                    <div className="preloader-icon">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <a href="#" className="scrollToTop"><i className="fas fa-angle-up"></i></a>
            <div className="overlay"></div>
        </>
    );
}

export default PreLoader;