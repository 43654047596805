import React from 'react';

function About() {
    return (
        <>

            <section className="page-header bg_img" data-background="./assets/images/page-header.png">
                <div className="bottom-shape d-none d-md-block">
                    <img src="./assets/css/img/page-header.png" alt="css"/>
                </div>
                <div className="container">
                    <div className="page-header-content cl-white">
                        <h2 className="title">About Us</h2>

                    </div>
                </div>
            </section>

            <section className="feature-section padding-top padding-bottom oh">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-header mw-100">
                                <h5 className="cate">Transform your business with Clerk Software's AI-powered solutions. Our expert team specializes in machine learning, mobile, and web development will elevate your organization to new heights.</h5>
                                <h2 className="title">Empower Your Life with Our All-in-One Solution.</h2>
                                <p className="mw-500">Unleash the full potential of your business with our comprehensive range of machine learning, web, and mobile application services. From mobile games and CMS plugins to robotic automation software and web design, our team is dedicated to delivering innovative solutions that drive success. Plus, we are constantly exploring new ideas to bring you the latest and greatest in software development.</p>

                            </div>
                        </div>
                    </div>
                    <ul className="nav nav-tabs feature-tab-menu">
                        <li>
                            <a data-toggle="tab" href="#collaboration" className="active">Customized Software Solutions for Your Business</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#productivity">Innovative New Software Ideas That Will Change the Game</a>
                        </li>
                        <li>
                            <a data-toggle="tab" href="#integrations">Expert WordPress Development and Support</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="collaboration">
                            <div className="row">
                                <div className="col-lg-6 col-md-10">
                                    <div className="feature-tab-header">
                                        <h3 className="title">Customized Software Solutions for Your Business</h3>
                                        <p>Clerk Software's services include custom application development packages designed to meet your unique business needs. Let our team of experts craft the perfect solution for you, including the following services:</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cola-area">
                                <div className="cola-item">
                                    <div className="col-md-6 col-lg-5 col-xl-4">
                                        <div className="cola-content">
                                            <div className="thumb">
                                                <img src="./assets/images/feature/colaboration.png"
                                                     alt="feature"/>
                                            </div>
                                            <span className="cate">Web</span>
                                            <h5 className="title">Web Design and Development That Delivers Results</h5>
                                            <ul>
                                                <li>Intuitive database design (Model) for maximum efficiency</li>
                                                <li>Mobile-optimized interface (View) for seamless viewing on all devices</li>
                                                <li>Easy-to-use administration panel (Controller) for hassle-free management</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                        <div className="cola-thumb">
                                            <img src="./assets/images/feature/feat1.png" alt="feature"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="cola-item">
                                    <div className="col-md-6 col-lg-5 col-xl-4">
                                        <div className="cola-content">
                                            <div className="thumb">
                                                <img src="./assets/images/feature/colaboration.png"
                                                     alt="feature"/>
                                            </div>
                                            <span className="cate">Mobile</span>
                                            <h5 className="title">Cutting-Edge Mobile Applications for Any Platform</h5>
                                            <ul>
                                                <li>Powerful Android apps</li>
                                                <li>Innovative iOS solutions</li>
                                                <li>Custom native development</li>
                                                <li>Versatile hybrid options</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                        <div className="cola-thumb">
                                            <img src="./assets/images/feature/feat3.png" alt="feature"/>
                                        </div>
                                    </div>
                                </div>


                                <div className="cola-item">
                                    <div className="col-md-6 col-lg-5 col-xl-4">
                                        <div className="cola-content">
                                            <div className="thumb">
                                                <img src="./assets/images/feature/colaboration.png"
                                                     alt="feature"/>
                                            </div>
                                            <span className="cate">Machine Learning</span>
                                            <h5 className="title">Revolutionize Your Business with Our Robotic Automation Software</h5>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                        <div className="cola-thumb">
                                            <img src="./assets/images/newslater/newslater2.png"
                                                 alt="feature"/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="productivity">
                            <div className="row">
                                <div className="col-lg-6 col-md-10">
                                    <div className="feature-tab-header">
                                        <h3 className="title">Innovative New Software Ideas That Will Change the Game</h3>
                                        <p>Clerk Software is not just a customer service provider, but a company that is always looking for new ways to innovate and push the boundaries. In the near future, we will be introducing our own original software ideas that are sure to make a splash in the industry.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="cola-area">
                                <div className="cola-item">
                                    <div className="col-md-6 col-lg-5 col-xl-4">
                                        <div className="cola-content">
                                            <div className="thumb">
                                                <img src="./assets/images/feature/productivity.png" alt="feature"/>
                                            </div>
                                            <span className="cate">Start-up</span>
                                            <h5 className="title">Unlock Your Business's Full Potential with Our Start-Up Project Development</h5>
                                            <ul>
                                                <li>Transform your ideas into successful projects</li>
                                                <li>Leverage our team's innovative minds to bring your vision to life</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                        <div className="cola-thumb">
                                            <img src="./assets/images/feature/feat6.png" alt="feature"/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="integrations">
                            <div className="row">
                                <div className="col-lg-6 col-md-10">
                                    <div className="feature-tab-header">
                                        <h3 className="title">Expert WordPress Development and Support</h3>
                                        <p>Take your WordPress site to the next level with Clerk Software's customized plugins. Visit <a href="https://pluginpress.net" target="_blank" rel="noreferrer">pluginpress.net</a> to see our portfolio and learn more. Plus, our team is here to resolve any issues you may have with your WordPress site. Trust us to keep your site running smoothly. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="cola-area">
                                <div className="cola-item">
                                    <div className="col-md-6 col-lg-5 col-xl-4">
                                        <div className="cola-content">
                                            <div className="thumb">
                                                <img src="./assets/images/feature/integrations.png" alt="feature"/>
                                            </div>
                                            <span className="cate">Wordpress</span>
                                            <h5 className="title">Custom WordPress Solutions for Your Business</h5>
                                            <ul>
                                                <li>Unique plugins to fit your specific needs</li>
                                                <li>Optimized for lightning-fast loading speeds</li>
                                                <li>Expert bug fixing to ensure smooth operation</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                        <div className="cola-thumb">
                                            <img src="./assets/images/feature/feat10.png" alt="feature"/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    );
}

export default About;