import PreLoader from "./components/PreLoader";
import Header from "./components/header/Header";
import Sections from "./components/sections/Sections";
import {BrowserRouter as Router} from "react-router-dom";
import Footer from "./components/sections/pages/Footer";
import {arrUrl,defaultScript} from "./context/script/script";
import {useEffect, useState} from "react";

function App() {
    const [scripts, setScripts] = useState([]);
    const defaultScripts = (url) => {
        const script = defaultScript(url);
        setScripts((prevState) => [...prevState, script]);
    };
    useEffect(() => {
        arrUrl.map((url) => defaultScripts(url));
    }, []);
  return (
      <Router>

          <PreLoader/>
          <Header/>
          <Sections/>
          <Footer/>

      </Router>
  );
}

export default App;
