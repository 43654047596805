export const defaultScript = (path) => {
    const script = document.createElement("script");
    script.src = path;
    script.async = false;
    script.type = "text/javascript";
    document.body.appendChild(script);
    return script;
}
export const prependScripts = (path) => {
    const script = document.createElement("script");
    script.src = path;
    script.async = false;
    document.body.prepend(script);
    return script;
}

export const removeScripts = (script) => {
    document.body.removeChild(script);
}

export const arrUrl = [
    `${process.env.PUBLIC_URL}/assets/js/jquery-3.3.1.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/modernizr-3.6.0.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/plugins.js`,
    `${process.env.PUBLIC_URL}/assets/js/bootstrap.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/magnific-popup.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/jquery-ui.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/wow.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/waypoints.js`,
    `${process.env.PUBLIC_URL}/assets/js/nice-select.js`,
    `${process.env.PUBLIC_URL}/assets/js/owl.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/counterup.min.js`,
    `${process.env.PUBLIC_URL}/assets/js/paroller.js`,
    `${process.env.PUBLIC_URL}/assets/js/main.js`,

]