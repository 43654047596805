import React, {useEffect} from 'react';
import {Link} from "react-router-dom";

function Main() {


    return (
        <>
            <section className="banner-7 bg_img oh bottom_right"
                     data-background="./assets/images/banner/banner-bg-7.jpg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="banner-content-7">
                                <h1 className="title">The Best "Coding" Clerk here</h1>
                                <p>Boost Your Business with Custom Web/Mobile Apps!
                                </p>
                                <div className="banner-button-group">
                                    <Link to="/contact" className="button-4 active">Contact us</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 d-lg-block d-none">
                            <img src="./assets/images/banner/banner-7.png" alt="banner"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="feature-section padding-top padding-bottom-2 oh pos-rel">
                <div className="feature-shapes style-two d-none d-lg-block">
                    <img src="./assets/images/feature/feature-shape2.png" alt="feature"/>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="section-header mw-100">
                                <h5 className="cate">An Extensive List of Amazing Features</h5>
                                <h2 className="title">Discover the Best Software for Your Business Needs</h2>
                                <p className="mw-500">Unleash the full potential of your business with our customizable software featuring numerous features tailored to your specific needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 rtl">
                            <div className="feature--thumb style-two pr-xl-4 ltr">
                                <div className="feat-slider owl-carousel owl-theme" data-slider-id="1">
                                    <div className="main-thumb">
                                        <img src="./assets/images/feature/pro-main2.png" alt="feature"/>
                                    </div>
                                    <div className="main-thumb">
                                        <img src="./assets/images/feature/pro-main3.png" alt="feature"/>
                                    </div>
                                    <div className="main-thumb">
                                        <img src="./assets/images/feature/pro-main.png" alt="feature"/>
                                    </div>
                                    <div className="main-thumb">
                                        <img src="./assets/images/feature/pro-main4.png" alt="feature"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="feature-wrapper mb-30-none owl-thumbs" data-slider-id="1">
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src="./assets/images/feature/pro5.png" alt="feature"/>
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Stay Ahead of the Game with Our Cutting-Edge Technology</h4>
                                        <p>Expertly tailor your machine or device's software with our specialized Embedded Systems Development services.</p>
                                    </div>
                                </div>
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src="./assets/images/feature/pro2.png" alt="feature"/>
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Enhance Your Productivity with Our Flexible and Easy-to-Use Solutions</h4>
                                        <p>Our team can cater to your specific web and mobile platform needs, and provide custom web and mobile app development solutions.</p>
                                    </div>
                                </div>
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src="./assets/images/feature/pro3.png" alt="feature"/>
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Effortlessly Manage Your Data with Our Customizable Dashboard</h4>
                                        <p>Gain complete control over all your data with our user-friendly dashboard designed specifically for your needs.</p>
                                    </div>
                                </div>
                                <div className="feature-item">
                                    <div className="feature-thumb">
                                        <div className="thumb">
                                            <img src="./assets/images/feature/pro4.png" alt="feature"/>
                                        </div>
                                    </div>
                                    <div className="feature-content">
                                        <h4 className="title">Designed for all devices</h4>
                                        <p>We can design your application compatible with all platforms.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="feat-nav">
                                <a href="#0" className="feat-prev"><i className="flaticon-left"></i></a>
                                <a href="#0" className="feat-next active"><i className="flaticon-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Main;